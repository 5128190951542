interface SectionProps {
  title: string;
  subtitle?: string;
  features: {
    title: string;
    subtitle: string;
    url: string;
    image: string;
    paragraphs: string[];
  }[];
}

export default function Section({ title, subtitle, features }: SectionProps) {
  const random = Math.floor(Math.random() * (6 - 1) + 1);
  return (
    <>
      <main className="relative w-full h-full min-h-screen overflow-x-hidden xl:overflow-hidden">
        <div className="relative">
          <div className="absolute inset-0 bg-gray-900 ">
            {/* <img
              className="min-h-screen w-screen object-cover object-bottom grayscale"
              src={`./images/vip${random}.jpg`}
              alt="People working on laptops"
            /> */}
            <div
              className="absolute inset-0 mix-blend-overlay bg-center bg-no-repeat bg-cover h-full w-screen bg-fixed"
              style={{
                backgroundImage: `url(../../images/lobos/lobo${random}.jpg)`,
              }}
            />
          </div>
          <div className="relative mx-auto max-w-screen-2xl py-48 px-6 md:px-8 lg:px-8 2xl:px-20">
            <div className="relative">
              <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-white sm:text-6xl">
                {title}
              </h2>
              <p className="mx-auto mt-4 max-w-3xl md:text-center text-xl text-gray-200">
                {subtitle}
              </p>
            </div>

            {features.map((feature, index) => (
              <div
                key={feature.title}
                className="relative mt-12 lg:mt-24 lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8"
              >
                <div
                  className={`relative ${
                    index % 2 !== 0 ? "lg:col-start-0" : "lg:col-start-2"
                  }`}
                >
                  <h3 className="text-2xl font-bold tracking-tight text-cyan-400 sm:text-3xl">
                    {feature.title}
                  </h3>
                  {feature.paragraphs.map((p) => (
                    <p
                      key={p}
                      className="mt-3 text-lg text-gray-200 md:text-left"
                    >
                      {p}
                    </p>
                  ))}
                </div>

                <div
                  className={`relative mt-10 lg:${
                    index % 2 !== 0 ? "ml-20" : "mr-20"
                  } lg:mt-0 ${
                    index % 2 !== 0 ? "lg:col-start-2" : "lg:col-start-1"
                  }`}
                  aria-hidden="true"
                >
                  <img
                    className="relative mx-auto rounded-xl"
                    src={feature.image}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </>
  );
}
