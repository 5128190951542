import { navigation } from "../../layout/Navbar";

export default function Home() {
  return (
    <main className="relative w-full h-full min-h-screen overflow-x-hidden xl:overflow-hidden">
      <div className="relative">
        <div className="absolute inset-0">
          <img
            className="h-full min-h-screen w-screen object-cover object-bottom grayscale"
            src="./images/home/home.jpeg"
            alt="Foto de lobo"
          />
          <div className="absolute inset-0 bg-gray-700 mix-blend-multiply" />
        </div>
        <div className="relative h-full min-h-screen w-screen flex items-center">
          <div className="max-w-screen-2xl mx-auto p-4 sm:p-6 md:p-8">
            <div className="mt-44 max-[1366px]:mt-24	">
              <div className="max-w-3xl">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                  <span className="block">Mikel Lejarza</span>
                  <span className="block text-cyan-400">Wolf Group</span>
                </h1>
                <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  Mikel Lejarza fue captado por los servicios secretos de España
                  para infiltrarse en la banda terrorista ETA con el apodo de EL
                  LOBO. Actualmente sigue colaborando para el CNI y todo su
                  equipo de trabajo estará dando apoyo a cualquier consulta.
                  Estos son algunos de los servicios que desde Mikel Lejarza
                  Wolf Group podemos suministrarle.
                </p>
              </div>
            </div>

            <div className="mt-16 grid grid-cols-1 gap-4 sm:gap-6 md:gap-8 sm:grid-cols-3 xl:grid-cols-5">
              {navigation.map((item) => (
                <>
                  {item.name !== "Contacto" && (
                    <a key={item.href} href={item.href} className="block">
                      <div className="p-4 h-80 bg-slate-600/50 rounded-xl cursor-pointer hover:bg-cyan-600/50 transition-all ease-in-out duration-200">
                        <div>
                          <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">
                            <item.icon
                              className="h-8 w-8 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                        <div className="mt-6">
                          <h3 className="text-lg font-semibold leading-8 text-white">
                            {item.name}
                          </h3>
                          <p className="mt-2 text-base leading-7 text-indigo-200">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    </a>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
