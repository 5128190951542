import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  AcademicCapIcon,
  Bars3Icon,
  CpuChipIcon,
  ShieldCheckIcon,
  StarIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

export const navigation = [
  {
    name: "Ciberseguridad",
    href: "/ciberseguridad",
    description:
      "Aplicar tantas capas de protección como sean necesarias, anticipando posibles brechas y protocolos de actuación.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Formación",
    href: "/formacion",
    description:
      "Estar formado para atajar las amenazas que nos encontramos en el día a día resulta crucial.",
    icon: AcademicCapIcon,
  },
  {
    name: "Infraestructura",
    href: "/infraestructura",
    description:
      "La seguridad en el plano físico es el bastión de nuestros datos, soluciones eficaces.",
    icon: CpuChipIcon,
  },
  {
    name: "Inteligencia",
    href: "/inteligencia",
    description:
      "Obtener el mayor número de datos y estructurarlos correctamente para contar con información fundamental para nuestros fines.",
    icon: UsersIcon,
  },
  {
    name: "VIP",
    href: "/vip",
    description:
      "La exposición continuada de los VIP merece un especial cuidado de su protección, reputación y su entorno más cercano.",
    icon: StarIcon,
  },
  {
    name: "Contacto",
    href: "https://correo.mikellejarza.com/contactform/wolf",
    description:
      "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    icon: StarIcon,
  },
];

function Navbar() {
  const [scrolled, setScrolled] = useState<boolean>(false);
  const { pathname } = useLocation();

  const changeScrolled = () => {
    if (window.scrollY >= 35) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  window.addEventListener("scroll", changeScrolled);

  return (
    <Popover
      as="header"
      className={`fixed inset-0 z-10 h-max w-screen px-6 py-2 md:px-8 md:py-2 lg:px-8 lg:py-4 2xl:px-20 transition ease-in-out duration-200 ${
        scrolled ? "bg-gray-900" : ""
      }`}
    >
      <nav
        className="relative mx-auto max-w-screen-2xl flex items-center justify-between"
        aria-label="Global"
      >
        <div className="flex flex-1 items-center justify-between">
          <div className="flex w-full items-center justify-between lg:max-w-max lg:justify-start lg:mr-10">
            <a href="/" className="flex items-center">
              <span className="sr-only">Mikel Lejarza</span>
              <img
                className={`w-auto mr-4 transition-all ease-in-out duration-200 ${
                  scrolled ? "h-12" : "h-24"
                }`}
                src="./images/logos/logo1.png"
                alt="Logo"
              />
            </a>
            <div className="flex items-center lg:hidden">
              <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-gray-200 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white">
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div className="hidden space-x-8 lg:flex">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={`text-base font-medium ${
                  pathname === item.href ? "text-cyan-400" : "text-white"
                } hover:text-cyan-400`}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
      </nav>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-10 origin-top transform transition lg:hidden"
        >
          <div className="overflow-hidden rounded-b-lg bg-gray-800 shadow-md">
            <div className="flex items-center justify-between px-5 pt-4">
              <div className="outline-none">
                <a href="/" className="flex items-center outline-none">
                  <span className="sr-only">Mikel Lejarza</span>
                  <img
                    className="w-auto mr-4 transition-all	 ease-in-out duration-300 h-24"
                    src="./images/logos/logo1.png"
                    alt="Logo"
                  />
                </a>
              </div>
              <div className="-mr-2 flex items-center lg:hidden">
                <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-gray-900 p-2 text-gray-200 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Close main menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="py-6">
              <div className="space-y-1 px-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="h-16 flex items-center w-fulltext-base font-medium text-white hover:text-gray-300"
                  >
                    <div className="mr-4">
                      <item.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <h3 className="text-lg leading-8 text-white">
                      {item.name}
                    </h3>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default Navbar;
