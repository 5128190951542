import Section from "../../layout/Section";

const features = [
  {
    title: "Formación en ciberseguridad",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/formacion-en-ciberseguridad",
    image: "./images/formacion/ciberseguridad.jpeg",
    paragraphs: [
      "La sociedad en la que vivimos exige mantener una renovación constante de los conocimientos y aptitudes que nos validen ante los desafíos que debemos afrontar en nuestro día a día.",
      "Desde Mikel Lejarza Wolf Group organizamos diversos cursos de formación tanto teóricos como prácticos para que aquellas organizaciones, empresas y administraciones puedan estar al día en cuanto a ciberseguridad, prevención de riesgos, protocolos de seguridad y actuación.",
      "Podemos diseñar cursos a medida en materia de ciberseguridad, tanto en materia defensiva como en la utilización y averiguación activa para la detención de criminales por parte de cuerpos y fuerzas de seguridad del estado.",
      "Adaptamos nuestros cursos al nivel necesario por parte de los usuarios que van a recibirlo para que su formación resulte completa y útil. Porque la seguridad, las buenas prácticas y los protocolos de actuación deben estar presentes en todos los niveles de una organización.",
      "Contacte con nosotros, plantee aquellas inquietudes y necesidades de formación que pueda tener tanto para sus directivos, equipos de trabajo o personal laboral.",
    ],
  },
  {
    title: "Formación CFSE",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/formacion-cfse",
    image: "./images/formacion/cfse.jpg",
    paragraphs: [
      "Desde Mikel Lejarza Wolf Group somos conscientes del constante reto que supone para los Cuerpos y Fuerzas de Seguridad del Estado estar al día en las nuevas amenazas que surgen constantemente.",
      "Es por ello que contamos con un amplio conocimiento en desgranar aquellas herramientas y metodologías que permitan realizar una mejor labor investigadora de cara a poner ante la justicia a los criminales cibernéticos.",
      "Formación muy específica, de manera presencial, para pequeños grupos seleccionados en materia de ciberterrorismo, ciberactivismo y vigilancia de sospechosos mediante medios digitales.",
      "Disponemos de formación física ante situación especiales, custodio de personalidades, protección de VIP, armamento, seguimiento y aproximación. Todo esto llevado a cabo por profesionales de la materia, acreditados y de total reconocimiento por los CFSE de todo el mundo.",
      "Debido al tipo de formación sensible y la importancia de que este conocimiento no caiga en manos erróneas, este tipo de formación sólo está disponible para CFSE, contando con la aprobación necesaria de los distintos cuerpos que lo integran.",
    ],
  },
];

function Formacion() {
  return (
    <Section
      title="Formación"
      subtitle="Ponemos a su disposición un amplio abanico de temas relacionados con la ciberseguridad de cara a formar a su empresa/equipo/personal. Formación a medida y personalizada para cada necesidad y entorno. Tanto a personal con conocimientos de IT o sin ellos."
      features={features}
    />
  );
}

export default Formacion;
