import Section from "../../layout/Section";

const features = [
  {
    title: "Servicios Gestionados",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/servicios-gestionados",
    image: "./images/ciberseguridad/servicios-gestionados.jpg",
    paragraphs: [
      "Desde Mikel Lejarza Wolf Group, contamos con la experiencia y el conocimiento para gestionar servicios para clientes, analizando la estructura actual disponible, proponiendo actuaciones de mejora y definiendo los puntos que necesitan una urgente corrección.",
      "Dotamos a su equipo de los medios necesarios para garantizar una comunicación segura, almacenamiento de datos relevantes, utilización de equipos informáticos y dispositivos electrónicos de manera segura.",
      "Mediante el empleo de soluciones EDR/XDR podremos evaluar las amenazas que se producen en sus sistemas de la manera más precisa, agilizando la toma de decisiones y salvaguarda de la infraestructura. ",
      "Sistemas de seguridad perimetral en su infraestructura para evitar accesos externos no deseados. Inspeccionando continuamente el tráfico y agilizando la detección de posibles brechas de seguridad.",
    ],
  },
  {
    title: "Protocolos de Actuación",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/protocolos-de-actuacion",
    image: "./images/ciberseguridad/protocolos-de-actuacion.jpg",
    paragraphs: [
      "A pesar de implementar todas las medidas posibles en Ciberseguridad siempre hay que disponer de unos protocolos de actuación en caso de que se produzca cualquier brecha.",
      "Tan importante es estar preparado como saber actuar en el momento que se produce cualquier incidente, interrumpiendo el evento y analizando la exposición.",
      "Esos primeros instantes son cruciales de cara a esclarecer la autoría del hecho delictivo y poder suministrar a las autoridades las evidencias faciliten la investigación.",
    ],
  },
];

function Ciberseguridad() {
  return (
    <Section
      title="Ciberseguridad"
      subtitle="Nuestro equipo de expertos en materia de ciberseguridad pueden asesorar a su empresa mediante formaciones y auditorías las cuales le ayudarán a mejorar la seguridad de su infraestructura, formando a su personal en aquellos temas que merezcan una especial atención, métodos y protocolos de actuación y contingencia."
      features={features}
    />
  );
}
export default Ciberseguridad;
