import { Route, Routes } from "react-router-dom";
import Home from "../public/Home";
import Ciberseguridad from "../public/Ciberseguridad";
import Formacion from "../public/Formacion";
import Infraestructura from "../public/Infraestructura";
import Inteligencia from "../public/Inteligencia";
import Vip from "../public/Vip";
import ErrorRouter from "./ErrorRouter";
import Page404 from "../../layout/404";

function router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="ciberseguridad" element={<Ciberseguridad />} />
      <Route path="formacion" element={<Formacion />} />
      <Route path="infraestructura" element={<Infraestructura />} />
      <Route path="inteligencia" element={<Inteligencia />} />
      <Route path="vip" element={<Vip />} />

      {/* Error routes */}
      <Route path="/" element={<ErrorRouter />}>
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default router;
