import Section from "../../layout/Section";

const features = [
  {
    title: "VIP Watchdog",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/vip-watchdog",
    image: "./images/vip/watchdog.jpg",
    paragraphs: [
      "Cualquier persona con exposición pública relevante necesita mantener una seguridad adicional en lo que a sus redes sociales y perfiles se refiere. Suelen ser víctimas de ataques que buscan dañar su imagen pública, causando perjuicios tanto en lo económico como en lo relativo a su prestigio e imagen pública.",
      "Es por ello que contamos con herramientas precisas que permitan mantener una vigilancia continua de estos perfiles, asegurando que se mantienen bajo control del legítimo propietario.",
      "Con nuestros sistemas somos capaces de permanecer alerta con respecto a cualquier mención, aparición de imágenes sensibles que puedan dañar su perfil, amenazas, etc.",
      "Realizar una continua monitorización de las redes para mantener la reputación del VIP a buen recaudo.",
      "En caso de que haya tenido un problema con sus cuentas puede contactar con nosotros, realizaremos las gestiones oportunas para recuperar sus perfiles y mitigar los daños que se puedan haber producido.",
    ],
  },
  {
    title: "VIP Escolta",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/vip-escolta",
    image: "./images/vip/escolta.jpg",
    paragraphs: [
      "Resulta indudable que la protección de personalidades en su día a día es una realidad, es por ello que contar con un equipo disciplinado, entrenado y preparado para cualquier tipo de evento resulta fundamental. Se trata de despliegues que requieren planificación, total transparencia en cuanto a origen-destino, amenazas conocidas, ambiente en el que se requiere protección y exposición al riesgo.",
      "Nos tomamos muy en serio la seguridad física de nuestros protegidos, es por ello que si tiene la necesidad de realizar un viaje, tiene previsto acudir a un evento, seguridad privada de manera permanente para usted o los suyos, está en el lugar indicado.",
      "Planificamos cada punto de la seguridad para que su seguridad quede completamente garantizada en cada momento.",
    ],
  },
];

function Vip() {
  return (
    <Section
      title="VIP"
      subtitle="Asesoramiento, planes de contingencia, monitorización activa de personalidades y empresarios de relevancia que necesitan mantener un alto estándar de seguridad personal tanto digital como física."
      features={features}
    />
  );
}
export default Vip;
