import Section from "../../layout/Section";

const features = [
  {
    title: "Cloud",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/cloud",
    image: "/images/infraestructura/cloud.jpg",
    paragraphs: [
      "Tanto si ya es usuario de Cloud Público (Amazon Web Services, Azure, Google Cloud, etc) como si está pensando en hacer uso de la misma, la seguridad debe ser uno de los pilares clave que marquen la dinámica de utilización de estos recursos.",
      "Es por ello que es fundamental implementar políticas de seguridad y revisión adicional en cuanto a posibles fugas de datos en el uso de almacenamiento de terceros, como de bases de datos distribuidas. Analizando con precisión quién tiene acceso a qué y acotar necesariamente los accesos y la verificación de quien accede a dichos datos.",
      "El acceso a aquellos sistemas desplegados en cloud público también debe hacerse de manera segura y con todas las garantías de que es un usuario legítimo quien accede, cualquier pequeño error podría ser totalmente nefasto de cara a sufrir un robo de datos confidenciales.",
    ],
  },
  {
    title: "Datacenter",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/datacenter",
    image: "/images/infraestructura/datacenter.jpg",
    paragraphs: [
      "Disponemos de los recursos necesarios para brindar una infraestructura totalmente enfocada a salvaguardar la integridad y seguridad de sus datos. Alojamiento de servidores, cabinas de almacenamiento y dispositivos electrónicos en instalaciones de primer nivel y con todas las medidas de seguridad tanto físicas como digitales necesarias para dar total cumplimiento legal en materia de seguridad.",
      "Servicios de alojamiento en España con total garantía y SLA.",
      "Posibilidad de proveer servicios de Cloud Privada para despliegue por parte de equipos de IT y pleno aprovechamiento de los recursos disponibles. Siempre enfocados en una perspectiva donde premie la seguridad, analizando todos los factores de entrada y salida para dar cumplimiento a los más estrictos estándares.",
    ],
  },
  {
    title: "Copias de Seguridad y Disaster Recovery",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/copias-de-seguridad-y-disaster-recovery",
    image: "/images/infraestructura/disaster-recovery-backup.jpg",
    paragraphs: [
      "La información y los datos son un pilar fundamental para el desarrollo de cualquier actividad hoy en día. Es por ello que disponer de una solución que garantice la integridad, resistencia y seguridad ante amenazas de sus datos se torna obligada.",
      "Diseñamos soluciones de copia de seguridad para todo tipo de datos. Tan importante es realizar las pertinentes copias, como verificar la integridad, custodia y disponibilidad de las mismas.",
      "Ofrecemos soluciones llave en mano y la formación pertinente a su personal para realizar una correcta política de copias de seguridad con todas las garantías de que sus datos permanecerán a buen recaudo.",
      "Diseño de soluciones Disaster Recovery ante catástrofes en la ubicación principal del cliente permitiendo la continuidad del servicio ante eventos inesperados.",
      "Replicación de su infraestructura a un centro de datos secundario desde el que poder operar en caso de catástrofe o eventual indisponibilidad del emplazamiento principal.",
    ],
  },
];

function Infraestructura() {
  return (
    <Section
      title="Infraestructura"
      subtitle="Planificación, contingencia, diseño e implementación de infraestructuras cloud y on-premise focalizadas en la seguridad de los datos, alojamiento en Centros de Datos Tier3+. Planes de migración/actualización de infraestructura actual con informes detallados de implementación, soluciones llave en mano."
      features={features}
    />
  );
}

export default Infraestructura;
