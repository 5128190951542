import Section from "../../layout/Section";

const features = [
  {
    title: "OSINT",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/osint",
    image: "./images/inteligencia/osint.jpg",
    paragraphs: [
      "Desde Mikel Lejarza Wolf Group conocemos la importancia en materia de inteligencia que tienen las fuentes abiertas, probablemente a ojos de una persona sin la formación necesaria estos son tan solo un montón de datos dispersos.",
      "Es por ello que contamos con desarrollos propios que permiten estructurar los datos de tal forma que faciliten la investigación de cualquier individuo, sociedad o activo. ",
      "Mediante la continua recopilación de estos datos, su organización y estructura podemos elaborar perfiles muy completos que faciliten labores de investigación, informes periciales y procesos judiciales.",
      "En todo momento damos cumplimiento a la legalidad vigente en materia de protección de datos.",
    ],
  },
  {
    title: "Informes de investigación",
    subtitle: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    url: "/informes-de-investigacion",
    image: "./images/inteligencia/informes.jpg",
    paragraphs: [
      "La realización de informes de investigación deben contar con un riguroso método y análisis de los datos que lo componen. Es la experiencia así como el conocimiento del investigador experimentado lo que puede hacer decantar la resolución de un conflicto con la inequívoca evidencia que arrojan los datos.",
      "Llevamos a cabo informes personalizados sobre personas, empresas, actividades y todo tipo de acciones que necesitan ser investigadas.",
    ],
  },
];

function Inteligencia() {
  return (
    <Section
      title="Inteligencia"
      subtitle="Realizamos todo tipo de informes mediante técnicas de investigación en fuentes abiertas, mediante el empleo de herramientas propias y metodología única. Logrando estructurar la información con un alto grado de confianza en la veracidad del dato."
      features={features}
    />
  );
}
export default Inteligencia;
