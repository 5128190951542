export default function Page404() {
  // const random = Math.floor(Math.random() * (6 - 1) + 1);
  const random = 1;
  return (
    <main>
      <div
        className="min-h-screen mix-blend-overlay grayscale bg-gray-900 bg-center bg-no-repeat bg-cover h-full w-screen bg-fixedsm:bg-top"
        style={{ backgroundImage: `url(../../images/lobos/lobo${random}.jpg)` }}
      >
        <div className="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-7xl font-semibold text-white">404</p>
          <h1 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
            Parece que te has separado de la manada.
          </h1>
          <p className="mt-2 text-lg font-medium text-white">
            Únete a nosotros y vuelve a casa.
          </p>
          <div className="mt-6">
            <a
              href="/"
              className="inline-flex items-center rounded-md border border-transparent bg-white bg-opacity-75 px-4 py-2 text-sm font-medium text-white text-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
            >
              Volver a Inicio
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}
